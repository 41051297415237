import { NavLink } from '@remix-run/react'
import cn from 'classnames'

import BlogCategoryLink from '@/components/BlogCategoryLink'
import { BlogCategory } from '@/utils/types'

export default function BlogCategoryNav() {
  return (
    <nav className='flex flex-wrap'>
      <NavLink
        className={({ isActive }) =>
          cn('px-sm', {
            'bg-gray-700 text-white hover:text-white dark:bg-gray-200 dark:text-black dark:hover:text-black': isActive,
            'text-contrast hover:bg-gray-100 hover:text-contrast dark:hover:bg-gray-800': !isActive
          })
        }
        end={true}
        to='/blog'
      >
        All
      </NavLink>
      <span className='px-sm text-decoration' role='presentation'>
        |
      </span>
      <BlogCategoryLink activeClassName={true} category={BlogCategory.Engineering} className='px-sm' />
      <span className='px-sm text-decoration' role='presentation'>
        |
      </span>
      <BlogCategoryLink activeClassName={true} category={BlogCategory.Vitess} className='px-sm' />
      <span className='px-sm text-decoration' role='presentation'>
        |
      </span>
      <BlogCategoryLink activeClassName={true} category={BlogCategory.Product} className='px-sm' />
      <span className='px-sm text-decoration' role='presentation'>
        |
      </span>
      <BlogCategoryLink activeClassName={true} category={BlogCategory.Tutorials} className='px-sm' />
      <span className='px-sm text-decoration' role='presentation'>
        |
      </span>
      <BlogCategoryLink activeClassName={true} category={BlogCategory.Company} className='px-sm' />
    </nav>
  )
}
